import Separator from "@/shared/Separator";
import { ParentGrid, InnerCardL, InnerCardM } from "./style";
import Banner from "@/widgets/Banner/Templates/Banner";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
import { BannerCajaGris } from "@/shared/ContentBanner";
import { useContainerWidget } from "hooks";

const Container3Pisos = (props) => {
  const { link, linkImage, sectionClass = "", title } = props;

  const { indexNews, titlesExtras, marfeelTitle } = useContainerWidget({
    arrayLength: 9,
    containerName: "Container3Pisos",
    ...props,
  });

  const defaultTemplate = [
    "TwoxTwoFoto",
    "OnexOne",
    "OnexOne",
    "OnexOne",
    "OnexOne",
    "OnexOne",
    "OnexOne",
    "Half",
    "Half",
  ];

  return (
    props.content?.length > 0 && (
      <>
        {title && (
          <Separator
            title={title}
            link={link}
            sectionClass={sectionClass}
            extras={titlesExtras}
            linkImage={linkImage}
          />
        )}
        <GridStyleColor id={marfeelTitle}>
          <ParentGrid
            data-mrf-recirculation={`${marfeelTitle}`}
            className={`Container3Pisos new module-grid ${sectionClass}`}
          >
            {indexNews.map((item, index) => {
              return (
                <>
                  {index < 7 ? (
                    <InnerCardL className={`div${index + 1}`}>
                      {props.renderContainerWidgetCallback(
                        item + 1,
                        props.positionProperties?.[index]?.templateNews ??
                          defaultTemplate[index],
                        {
                          containerName: "contenedor_3_pisos",
                          dynamicType: props.positionProperties?.[index]?.dynamicType ?? "default",
                        }
                      )}
                    </InnerCardL>
                  ) : (
                    <InnerCardM className={`div${index + 1}`}>
                      {props.renderContainerWidgetCallback(
                        item + 1,
                        props.positionProperties?.[index]?.templateNews ??
                          defaultTemplate[index],
                        {
                          containerName: "contenedor_3_pisos",
                        }
                      )}
                    </InnerCardM>
                  )}
                </>
              );
            })}
            <div className="div10">
              <BannerCajaGris>
                <Banner
                  isPreview={props.isPreview}
                  slotId="caja2"
                  adsPath="home"
                />
              </BannerCajaGris>
            </div>
          </ParentGrid>
        </GridStyleColor>
      </>
    )
  );
};

export default Container3Pisos;
